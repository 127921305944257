@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "Roboto";
  src: url("./fonts/roboto.woff") format("woff2"), url("./fonts/roboto.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/robotomedium.woff2") format("woff2"), url("./fonts/robotomedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/robotobold.woff2") format("woff2"), url("./fonts/robotobold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --basic-black: #000000;
  --basic-orange: #E73E17;
  --basic-orange-hover: #ff0c0c;
  --basic-orange-active: #f75629;
  --social-orange-hover: #f3704b;
  --basic-white: #ffffff;
  --light-gray: rgba(255, 255, 255, 0.3);
  --background-gray: #eeeeee;
  --basic-gray: #adadad;
  --basic-gray2: #dfdfdf;
  --basic-gray3: #d5d5d5;
  --gray-active: rgba(0, 0, 0, 0.3);
  --gray-hover: rgba(0, 0, 0, 0.6);
  --btn-gray-shadow: rgba(0, 1, 1, 0.1);
  --border-gray-btn: #dbdbdb;
  --products-text: #666666;
  --products-description-gray: #4d4d4d;
  --social-gray: #e1e1e1;
  --basic-blue: #2f32f3;
  --alert-green: #4bcb49;
  --toggle-gray: #ababab;
  --toggle-shadow: #cfcfcf;
  --price-controls-color: #283136;
  --slider-button: #c4c4c4;
  --modal-input: #d7dcde;
  --modal-input-hover: #b4b9bb;
  --close-button: #feccce;
  --close-button-active: #ffeeef;

}


body {
  margin: 0;
}



.study {
  border-bottom: 2px solid var(--basic-orange);
}

.carousel-position{
  margin-top: 10px;
}

.sliderItemOne {
  margin-top: 12px;
  margin-bottom: 10px;
  background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5) ), url('./img/slides/connections.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: aliceblue;
  align-content: center;
}

.sliderItemTwo {
  margin-top: 12px;
  margin-bottom: 10px;
  background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5) ), url('./img/slides/personalapproach.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: aliceblue;
  align-content: center;
}

.sliderItemThree {
  margin-top: 12px;
  margin-bottom: 10px;
  background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5) ), url('./img/slides/society.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: aliceblue;
  align-content: center;  
}

.approaches {
  margin: 10px;
}

.advantageCard {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 20rem;
  border: none;
}

.contentPosition {
  border: none;
  margin: auto;
  align-items: center;
}
.mapBgt{
  background: linear-gradient( rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.8) ), url('./img/map.png');
  background-size: cover;
}

.mapBgt p {
  padding: 20px;
}

.contactsText {
  background-color: #ffffff;
  padding: 0%;
  margin: auto;
  display: inline;
}

.contact-info {
 line-height: normal;
 padding-top: 20px;

}

.contactTextWrapper {
  display: inline;
  padding: 0%;
  margin: auto;
  background-color: black;
}

.sliderContent {
  align-items: center;
  text-align: center;
}

.mapContactButton {
  padding: 20px;
  margin-top: auto;
}

.nav-contact {
  margin: auto;
}

.main-navigation {
  align-items: center;
}

.contact-button-nav {
  display: none;
}

@include media-breakpoint-between(xs, md) { 
  .contact-button-nav {
    margin-top: 10px;
    display: block;
    align-items: center;
  }

  .mobile-button-nav {
    display:block;
  }
 
}

.button {
  background-color: var(--basic-orange);
  color: var(--basic-white);
  border: none;
  margin-left: 20px;
}

.button:hover {
  background-color: var(--basic-orange-hover);
}

.button:active {
  color: var(--light-gray);
  box-shadow: inset 0px 3px 0px var(--btn-gray-shadow);
  background: linear-gradient(0deg, var(--basic-orange-active), var(--basic-orange-active)), var(--basic-white);
}

.discover-button a {
  text-decoration: none;
  color: var(--basic-white);
}

.discover-button-position {
  align-items: center;
}

.nav-logo{
  font-size:large;
}

.main-navigation a {
  font-weight: 500;
  text-transform: uppercase;
  font-size:medium;
}

.main-navigation a {
  color: var(--basic-black);
  font-weight: 500;
  line-height: 30px;
}

.main-navigation a:hover {
  color: var(--basic-orange);
}

.main-navigation a:active {
  color: var(--basic-black);
  opacity: 0.3;
}

.checkbox-note {
  color: var(--basic-blue);
  font-size: small;
}

//Statistics and Services//




.services-list li:not(:last-child) {
  margin-bottom: 23px;
}

.services-list li {
  position: relative;
  padding-left: 37px;
  margin-bottom: 5%;
}

.services-list li::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 25px;
  height: 2px;
  background-color: var(--basic-orange);
}

.services-list li:last-child::before {
  height: 1px;
}

.line-gray {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 2px solid var(--background-gray);
  border-bottom: 2px solid var(--background-gray);
}

.statistics {
  margin-top: 20px;
}

.statistics-content th:not(:last-child),
.statistics-content td:not(:last-child) {
  margin: 0;
  padding: 0;
  padding-right: 20px;
}

.statistics-content {
  margin-top: 23px;
  margin-left: 25px;
}

.statistics-content th {
  text-align: left;
}

.statistics-content caption {
  margin: 0;
  margin-bottom: 31px;
}

.statistics img {
  margin-left: 30px;
}

.slogan {
  font-size: 45px;
  line-height: 45px;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.services-description {
  margin: 0;
  margin-bottom: 40px;
}

.services-orders {
  margin: 0;
  margin-bottom: 23px;;
}

.text-uppercase {
  text-transform: uppercase;
  font-weight: 700;
}

.clear-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion-about {
  margin-top: 20px;
  margin-bottom: 20px;
}

//partners section//

.partners-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid var(--background-gray);
  border-bottom: 2px solid var(--background-gray);
  padding-top: 43px;
  padding-bottom: 43px;
  margin-top: 43px;
}

.partners {
  margin-bottom: 80px;
}

.contacts-list h2 {
  margin-top: 0;
}

.partners-list a {
  opacity: 0.7;
}

.partners-list a:hover {
  opacity: 1;
}

.partners-list a:active {
  opacity: 0.4;
}

.partnersOpportunity {
  text-decoration: none;
}

.partners-list .contacts-btn{
  opacity: 1;
}



//Footer//

.footer-columns {
  display: flex;
  align-items: center;

}

.footer-social {
  margin-left: auto;
  text-align: center;

}

.main-footer {
  margin-bottom: 68px;
  padding-top: 60px;
}

.social-list {
  display: flex;
}

.social-space {
  width: 81px;
  height: 81px;
}

.social-space-one {
  width: 41px;
  height: 81px;
}


.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 81px;
  height: 81px;
  background-color: var(--basic-gray);
  color: inherit;
  border-radius: 50%;
}

.social-button:hover {
  background-color: var(--basic-orange-active);
  background: linear-gradient(0deg, var(--social-orange-hover), var(--basic-orange-hover)), var(--basic-white);
}

.social-button:active path {
  fill: var(--light-gray);

}

.social-button:active {
  box-shadow: inset 0px 3px 0px var(--btn-gray-shadow);
  background: linear-gradient(0deg, var(--basic-orange-active), var(--basic-orange-active)), var(--basic-white);
}

.social-list li:not(:last-child) {
  margin-right: 9px;
}

.cta p:first-child {
  margin-top: -10px;
  margin-bottom: 10px;
}

.cta p:last-child {
  margin: 0;
}

.registrationInfo {
  margin-top: 20px;

}


//Modal//

.modal-header {
  border: none;

}

.modal-footer {
  border: none;
}

.modal-btn {
  border: none;
  color: var(--basic-white);
  cursor: pointer;
  margin: 1em;
}

.container-modal {
  padding-top: 1em;
  padding-bottom: 1em;
}

.text-agreement {
  font-size: 10px;
  line-height: 1;
}

.modal-title  {
  font-size: 30px;
}

.file-upload {
  padding-top: 10px;
  padding-bottom: 10px;
}